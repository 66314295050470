.favorites-list-chip{
    .panel{
        img{
            width: 100%;
        }
        .panel-footer{
            padding: 10px;
            min-height: 46px;
        }
    }
}
