.back-to-top{
	display: none;
	position: fixed;
	right: 20px;
	bottom: 60px;
	background-color: #323234;
	a{
		color: white;
		
	}
	&:hover{
		background-color: #454547;
		& > a{
			/*color: #adadad;*/
		}
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) {
		bottom: 20px;
	}
}
